import Vue    from "vue";
import Router from "vue-router";

import * as Sentry    from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(Router);

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve, reject) => {
			if(to.hash) {
				setTimeout(() => {
					resolve({ selector: to.hash });
				}, 600);
			}

		});
	},
	routes: [],
});

Sentry.init({
	Vue,
	dsn         : "https://5770356df02e415db9cbcdf54425feec@cabinet.chatkeeper.app/clientErrorHandler/2",
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 20.0,
});

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject);
	}

	return originalPush.call(this, location).catch((err) => {
		if (Router.isNavigationFailure(err, Router.NavigationFailureType.cancelled) || Router.isNavigationFailure(err, Router.NavigationFailureType.duplicated)) {
			return err
		}

		return Promise.reject(err)
	})
}

export default router;
